* {
  box-sizing: border-box;
}

body {
  background: #f7f7f7;
}

textarea:focus-visible {
  outline: none;
}

a {
  text-decoration: none;
}
